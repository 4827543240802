/*
 * @Description:
 * @Autor: liuguangkuo
 * @Date: 2020-03-06 11:30:52
 * @LastEditTime: 2021-03-31 14:14:48
 */
/**
 * 静态页面路由文件
 */
let routes = [
  //ios训练投屏助手
  {
    path: "/static/screen",
    name: "screen",
    component: require("@/views/static/screen").default,
    meta: {
      title: "投屏助手"
    }
  },
  //大师投屏助手
  {
    path: "/static/screenCoach",
    name: "screenCoach",
    component: require("@/views/static/screenCoach").default,
    meta: {
      title: "投屏助手"
    }
  },
  //用户协议
  {
    path: "/static/agreement",
    name: "staticAgreement",
    component: require("@/views/static/agreement").default,
    meta: {
      title: "用户协议"
    }
  },
  //隐私条款
  {
    path: "/static/privacy",
    name: "staticPrivacy",
    component: require("@/views/static/privacy").default,
    meta: {
      title: "隐私条款"
    }
  },
  // 鸿蒙隐私条款
  {
    path: "/static/harmonyprivacy",
    name: "staticHarmonyprivacy",
    component: require("@/views/static/harmonyprivacy").default,
    meta: {
      title: "隐私条款"
    }
  },
    // 鸿蒙隐私条款明细
  {
    path: "/static/harmonyDetail",
    name: "staticHarmonyDetail",
    component: require("@/views/static/harmonyDetail").default,
    meta: {
      title: "隐私条款"
    }
  },

  //注意事项
  {
    path: "/static/notice",
    name: "staticNotice",
    component: require("@/views/static/notice").default,
    meta: {
      title: "注意事项"
    }
  },
  //超级私教留名单评估报告
  {
    path: "/static/cjsjReport",
    name: "cjsjReport",
    component: require("@/views/static/cjsjReport").default,
    meta: {
      title: "评估课报告"
    }
  },
  //外食建议
  {
    path: "/static/diet",
    name: "diet",
    component: require("@/views/static/diet").default,
    meta: {
      title: "外食建议"
    }
  }
];
export default routes;
